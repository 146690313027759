section[role="dialog"] {
  max-height: 80%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
}

.about-location-wrapper {
  width: 100%;
}

.map-box .leaflet-container {
  max-height: 150px !important;
}

@media screen and (max-width: 639px) {
  .map-box .leaflet-container {
    max-height: 150px !important;
  }

  .about-location-wrapper {
    width: 100%;
  }

  section[role="dialog"] {
    width: 80%;
  }
}

@media screen and (min-width: 640px) {
  .about-location-wrapper {
    width: 310px;
  }

  section[role="dialog"] {
    width: 430px;
  }

  .map-box .leaflet-container {
    max-height: 224px !important;
  }
}

.about-edit-img .filepond--root,
.about-edit-img .filepond--wrapper {
  min-height: 195px;
  max-height: 195px;
}

.scrapbook-edit-img .filepond--root,
.scrapbook-edit-img .filepond--wrapper {
  min-height: 171px;
  max-height: 171px;
}

@media screen and (min-width: 640px) {

  .about-edit-img .filepond--root,
  .about-edit-img .filepond--wrapper {
    min-height: 290px;
    max-height: 290px;
  }
}

@media screen and (max-width: 639px) {

  .about-edit-img .filepond--root,
  .about-edit-img .filepond--wrapper {
    min-height: 195px;
    max-height: 195px;
  }
}