@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("./auth/_toastify.css");

.loader {
  display: flex;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.cl-formFieldInput {
  border-radius: 8px !important;
}

.loader.show {
  /* height: 0; */
  /* opacity: 0; */
  display: none;
  /* visibility: hidden; */
}

/*primary color*/
.bg-cream {
  background-color: #fff2e1;
}

/*font*/
body {
  font-family: "Poppins", sans-serif;
}

.bg-yellow-500 {
  background-color: #f48c06;
}

.text-yellow-500 {
  color: #f48c06;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 8px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floating-4 {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-4 {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 8px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.text-darken {
  color: #2f327d;
}

.ReactTags__error {
  display: flex !important;
  align-items: center !important;
  column-gap: 5px !important;
  color: rgb(240 82 82);
  margin-top: 5px !important;
}

.error-tag {
  background-color: hsl(339.13 92% 95.1%);
}