@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap");

body {
  font-family: "Arimo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

@media screen and (max-width: 315px) {
  .small-phone-img {
    left: 0 !important;
  }
}

::placeholder {
  color: #bbbbbb !important;
}

.search-input::placeholder {
  color: #424C9B !important;
  opacity: .7;
}

.search-input {
  opacity: .7;
}

*:focus-within:not(input):not(a):not(.importanceBtn):not(.invalid-text):not(
    .no-focus
  ):not(.filepond--wrapper):not(.share-frond-input),
*:focus:not(input):not(a):not(.importanceBtn):not(.invalid-text):not(
    .no-focus
  ):not(.filepond--wrapper):not(.share-frond-input) {
  border-color: #e5e4eb !important;
}

.invalid-text:focus,
.invalid-text:focus-within {
  border-color: #f31260 !important;
}

.search-input-box:focus,
.search-input-box:focus-within,
.search-input-box:focus-visible,
.search-input-box:hover {
  border-color: #F5F6FF !important;
}

.gradiantBorder,
.gradiantBorder:focus {
  border: 2px solid transparent !important;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.smallBorder,
.smallBorder:focus {
  border-width: 1px !important;
}

.largeBorder,
.largeBorder:focus {
  border-width: 3px !important;
}

.count-border {
  border-width: 1.13px;
}

.normalBorder {
  border-color: #e5e4eb !important;
}

.focusBorder {
  border-color: #5ccdb0 !important;
}

@media screen and (max-width: 414px) {
  .contact-form-input-container {
    flex-wrap: wrap;
    gap: 15px !important;
  }
}

@media screen and (max-width: 515px) {
  .auth-contact-form-input-container {
    flex-wrap: wrap;
    gap: 15px !important;
  }
}

@media screen and (max-width: 319px) {
  .contact-buble {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 310px) {
  .rights-popUp {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.search-navbar input,
.search-navbar input {
  -webkit-text-fill-color: #424c9bb2 !important;
  opacity: 70% !important;
  font-weight: normal !important;
  font-size: 16px !important;
  padding-inline-start: 10px !important;
}

.search-navbar:focus input,
.search-navbar:hover input,
.search-navbar:focus-within input,
.search-navbar:hover .in-wrap,
.search-navbar:focus .in-wrap,
.search-navbar:focus-within .in-wrap,
.search-projects:focus input,
.search-projects:hover input,
.search-projects:focus-within input,
.search-projects:hover .in-wrap,
.search-projects:focus .in-wrap,
.search-projects:focus-within .in-wrap {
  background-color: #f5f6ff !important;
}

.search-projects:focus input,
.search-projects:hover input,
.search-projects:focus-within input,
.search-projects:hover .in-wrap,
.search-projects:focus .in-wrap,
.search-projects:focus-within .in-wrap {
  background-color: #fff !important;
}

@media screen and (min-width: 640px) {
  .search-projects input {
    padding-inline-start: 10px !important;
  }
}

@media screen and (max-width: 639px) {
  .search-projects input {
    padding-inline-start: 5px !important;
  }
}

.filepond--credits {
  display: none;
}

.filepond--wrapper {
  border-radius: 20px;
  overflow: hidden;
  border: 1px dashed #bbbbbb;
  background-color: #f5f6ff;
  cursor: pointer;
}

.no-border {
  border: none;
}

@media screen and (max-width: 410px) {
  .create-img-input .filepond--wrapper {
    min-height: 180px;
    max-height: 180px;
  }

  .create-img-input .filepond--root {
    min-height: 180px;
    max-height: 180px;
  }
}

@media screen and (min-width: 410px) {
  .create-img-input .filepond--wrapper {
    min-height: 289.6px;
    max-height: 289.6px;
  }

  .create-img-input .filepond--root {
    min-height: 289.6px;
    max-height: 289.6px;
  }
}

/* .filepond--wrapper:focus {
  border: 1px dashed #BBBBBB !important;
} */

.filepond--root {
  margin-bottom: 0;
  border-radius: 20px;
  background-color: #f5f6ff;
}

.filepond--drop-label {
  background-color: #f5f6ff;
  width: 100%;
  height: 100%;
}

.filepond--panel-root {
  background-color: #f5f6ff;
}

.filepond--drop-label.filepond--drop-label label {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 410px) {
  .filepond--drop-label.filepond--drop-label label img {
    width: 28px;
    height: 28px;
  }

  .filepond--drop-label.filepond--drop-label label span {
    padding: 4px 8px;
    font-size: 10px;
  }
}

@media screen and (min-width: 410px) {
  .filepond--drop-label.filepond--drop-label label img {
    width: 42px;
    height: 42px;
  }

  .filepond--drop-label.filepond--drop-label label span {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.filepond--drop-label.filepond--drop-label label img {
  margin-bottom: 10px;
}

.filepond--drop-label.filepond--drop-label label p {
  color: #bbbbbb;
  font-size: 14px;
  margin-bottom: 10px;
}

.filepond--drop-label.filepond--drop-label label p:last-of-type {
  margin-bottom: 15px;
}

.filepond--drop-label.filepond--drop-label label span {
  background-color: #424c9b;
  box-shadow: 0px 2px 8px 0px #00000026;
  border-radius: 30px;
  color: #fff;
}

.leaflet-container {
  height: 224px !important;
}

@media screen and (max-width: 410px) {
  .leaflet-container {
    height: 180px !important;
  }
}

@media screen and (min-width: 1024px) {
  .leaflet-container {
    height: 289.6px !important;
  }
}

@media screen and (min-width: 410px) and (max-width: 1023px) {
  .leaflet-container {
    height: 224px !important;
  }
}

.leaflet-container {
  width: 100%;
  border-radius: 20px;
}

.leaflet-control {
  display: none;
}

.date-picker-frond {
  color: #2b2b2b !important;
}

.date-picker-frond ~ .date-picker-frond:not([aria-valuetext]) {
  color: #2b2b2b !important;
}

[data-slot="inner-wrapper"] .date-picker-separator {
  color: #2b2b2b !important;
}

.date-picker-frond[aria-valuetext="Empty"] {
  color: #bbbbbb !important;
}

.date-picker-frond[aria-valuetext="Empty"]
  ~ .date-picker-frond:not([aria-valuetext]) {
  color: #bbbbbb !important;
}

[data-slot="start-input"]:not([value]) ~ .date-picker-separator {
  color: #bbbbbb !important;
}

.custom-input-value[data-has-value="true"] [data-slot="value"] {
  color: #2b2b2b !important;
}

.tagInputField:focus:not(.invalid-text) {
  border-color: #e5e4eb !important;
}

.tagInputField:hover:not(.invalid-text) {
  border-color: #6b7280 !important;
}

.tagInputField.invalid-text {
  border-color: #f31260;
}

.input-noMargin {
  margin-top: 0 !important;
}

.error-tag {
  background-color: #f31260 !important;
}

.ReactTags__error {
  display: none !important;
}

@media screen and (max-width: 639px) {
  .tags:not("filter-tags") .tagInputField {
    background-image: url("./Assets//create-frond//like.svg");
    padding-left: 35px;
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 12px;
  }
}

@media screen and (min-width: 640px) {
  .tags:not("filter-tags") .tagInputField {
    background-image: url("./Assets//create-frond//like.svg");
    padding-left: 47px;
    background-position: 18px center;
    background-repeat: no-repeat;
    background-size: 18px;
  }
}

@media screen and (max-width: 525px) {
  .goals-card {
    padding-left: 40px;
    padding-right: 40px;
  }

  .qr-box {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}

/*
  Loading
*/

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(-360deg);
  }
}

.spinner {
  animation: rotate 1s linear infinite;
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
  border-radius: 50%;
  height: 75px;
  width: 75px;
  position: absolute;
  left: 0;
  top: 0;
}

.spinner::before,
.spinner::after {
  content: "";
  position: absolute;
}

.spinner::before {
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        hsla(0, 0%, 100%, 1) 50%,
        hsla(0, 0%, 100%, 0.9) 100%
      )
      0% 0%,
    linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.9) 0%,
        hsla(0, 0%, 100%, 0.6) 100%
      )
      100% 0%,
    linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0.6) 0%,
        hsla(0, 0%, 100%, 0.3) 100%
      )
      100% 100%,
    linear-gradient(
        360deg,
        hsla(0, 0%, 100%, 0.3) 0%,
        hsla(0, 0%, 100%, 0) 100%
      )
      0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.spinner::after {
  background: white;
  border-radius: 50%;
  top: 5%;
  bottom: 5%;
  left: 5%;
  right: 5%;
}

.child-load {
  animation: rotate2 1s linear infinite;
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  position: absolute;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 999;
}

.child-load::before,
.child-load::after {
  content: "";
  position: absolute;
}

.child-load::before {
  border-radius: 50%;
  background: linear-gradient(
        0deg,
        hsla(0, 0%, 100%, 1) 50%,
        hsla(0, 0%, 100%, 0.9) 100%
      )
      0% 0%,
    linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0.9) 0%,
        hsla(0, 0%, 100%, 0.6) 100%
      )
      100% 0%,
    linear-gradient(
        180deg,
        hsla(0, 0%, 100%, 0.6) 0%,
        hsla(0, 0%, 100%, 0.3) 100%
      )
      100% 100%,
    linear-gradient(
        360deg,
        hsla(0, 0%, 100%, 0.3) 0%,
        hsla(0, 0%, 100%, 0) 100%
      )
      0% 100%;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.child-load::after {
  background: white;
  border-radius: 50%;
  top: 8%;
  bottom: 8%;
  left: 8%;
  right: 8%;
}

.joinFrond-input:hover,
.joinFrond-input:focus,
.joinFrond-input:focus-within {
  background-color: #fff !important;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

.custom-toast {
  border-radius: 10px !important;
  font-weight: bold !important;
  margin-bottom: 0 !important;
  background-color: #fff !important;
  margin-bottom: 20px !important;
}

.success-toast {
  box-shadow: 2px 2px 10px 0 rgba(34, 187, 51, 0.5),
    -2px -2px 10px 0 rgba(34, 187, 51, 0.5) !important;
}

.error-toast {
  box-shadow: 2px 2px 10px 0 rgba(187, 33, 36, 0.5),
    -2px -2px 10px 0 rgba(187, 33, 36, 0.5) !important;
}

.custom-toast > div:first-child {
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.custom-toast button svg {
  width: 18px !important;
  height: 18px !important;
}

.success-toast button svg {
  fill: #22bb33 !important;
}

.error-toast button svg {
  fill: #bb2124 !important;
}

.create-frond-desc {
  min-height: 116px !important;
  height: 116px !important;
  max-height: 116px !important;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .custom-media {
    grid-template-columns: 1fr !important;
  }
}

.tooltip-box ~ .tooltip {
  visibility: hidden;
}

.tooltip-box:hover ~ .tooltip {
  opacity: 1;
  visibility: visible;
}

.profile-nav header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media screen and (min-width: 460px) and (max-width: 639px) {
  .search-projects input::placeholder {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 640px) {
  .search-projects input::placeholder {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 459px) {
  .search-projects input::placeholder {
    font-size: 8px !important;
  }
}

@media screen and (min-width: 460px) and (max-width: 639px) {
  .invitaion-search input::placeholder {
    font-size: 12px !important;
  }
}

@media screen and (min-width: 640px) {
  .invitaion-search input::placeholder {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 459px) {
  .invitaion-search input::placeholder {
    font-size: 12px !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: inset 0 0 20px 20px transparent;
}

.frond-details-map {
  height: 224px !important;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.discover-map {
  height: 198px !important;
  border-radius: 20px !important;
}

.temp-map-details {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.tabs-wrapper {
  position: relative;
  height: 100%;
}

.tabs-wrapper .tabs-content {
  padding-bottom: 20px;
}

.scrapbook-tab[data-selected="true"] span {
  background-color: #424c9b;
}

.tasklist-tab[data-selected="true"]:first-of-type span {
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
}
.tasklist-tab[data-selected="true"]:nth-of-type(2) span {
  background-color: #424c9b;
}
.tasklist-tab[data-selected="true"]:nth-of-type(3) span {
  background-color: #eb6270;
}
.tasklist-tab[data-selected="true"]:last-of-type span {
  background-color: #5ccdb0;
}

.discover-tab[data-selected="true"] > span {
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
}

.tasklist-tab[data-selected="true"] div,
.scrapbook-tab[data-selected="true"] div,
.discover-tab[data-selected="true"] div {
  color: white !important;
}

.audio-react-recorder canvas {
  display: none;
}

.main-tabs[data-selected="true"] span {
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
}

/* Ensure all focus states are properly handled */
.main-tabs span:focus-within,
.main-tabs span:focus-visible,
.main-tabs span:focus,
.main-tabs span,
.main-tabs:focus-visible,
.main-tabs:focus-within,
.main-tabs:focus,
.main-tabs {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  outline-color: #fff !important;
}

/* .scrollbar-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 20px;
  background-color: white;
  z-index: 1;
} */

.privacy-modal,
.terms-modal {
  width: auto !important;
}

.share-frond-input:focus,
.share-frond-input:focus-within,
.share-frond-input:hover {
  border-color: #f5f6ff !important;
}

.share-modal {
  border-radius: 20px !important;
}

.confirm-modal {
  border-radius: 20px !important;
}

.invite-modal [role="dialog"] {
  min-height: 70%;
  max-height: 70%;
}

@media screen and (max-width: 640px) {
  .invite-modal section[role="dialog"] {
    width: 420px !important;
  }
}

@media screen and (max-width: 459px) {
  .invite-modal section[role="dialog"] {
    width: 310px !important;
  }
}

@media screen and (max-width: 639px) {
  [data-testid="grid-gallery-item_thumbnail"] {
    width: 200px;
    object-fit: cover;
  }
}

[data-testid="grid-gallery-item_thumbnail"] {
  border-radius: 5px;
}

.date-box {
  border-radius: 8px !important;
}

.user-select-input {
  gap: 10px !important;
}

.ReactGridGallery_custom-overlay {
  opacity: 1 !important;
}

.task-group {
  background-color: #f4f5f7;
  border-radius: 8px;
  padding: 10px;
  width: 300px;
}

.task-group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.task-list {
  margin-top: 10px;
}

.task-item-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.change-pending-task:hover {
  color: #fff !important;
  background-color: #424c9b !important;
}
.change-completed-task:hover {
  color: #fff !important;
  background-color: #5ccdb0 !important;
}
.change-inProgress-task:hover {
  color: #fff !important;
  background-color: #eb6270 !important;
}

/* @media screen and (max-width: 415px) {
  .rhap_container {
    width: 100px !important;
  }
} */

.add-post-input [data-slot="main-wrapper"]:hover,
.add-post-input [data-slot="input-wrapper"]:hover,
.add-post-input [data-slot="main-wrapper"]:focus,
.add-post-input [data-slot="input-wrapper"]:focus,
.add-post-input [data-slot="main-wrapper"]:focus-within,
.add-post-input [data-slot="input-wrapper"]:focus-within {
  background-color: #f5f6ff !important;
}

.add-post-input input,
.add-post-input input::placeholder {
  font-size: 16px !important;
}

.discover-search-input [data-slot="main-wrapper"]:hover,
.discover-search-input [data-slot="input-wrapper"]:hover,
.discover-search-input [data-slot="main-wrapper"]:focus,
.discover-search-input [data-slot="input-wrapper"]:focus,
.discover-search-input [data-slot="main-wrapper"]:focus-within,
.discover-search-input [data-slot="input-wrapper"]:focus-within {
  background-color: #fff !important;
}

.discover-search-input input,
.discover-search-input input::placeholder {
  font-size: 16px !important;
}

@media screen and (min-width: 640px) {
  .discover-filter-modal section[role="dialog"] {
    min-width: 509px !important;
    max-width: 509px !important;
  }
}

@media screen and (max-width: 639px) {
  .discover-filter-modal section[role="dialog"] {
    min-width: 80% !important;
    max-width: 80% !important;
  }
}

.sort-modal section[role="dialog"] {
  min-width: 220px !important;
  max-width: 220px !important;
}

.radio-filter[data-selected="true"] > span:nth-child(2) {
  border: 1px solid transparent !important;
  background-image: linear-gradient(white, white),
    linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%) !important;
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.radio-filter > span:nth-child(2) {
  border: 1px solid #bbbbbb !important;
}

.radio-filter > div span {
  font-weight: 400;
  transition: all 200ms ease;
}

.radio-filter[data-selected="true"]:not("sort-fronds") > div span {
  font-weight: 700;
}

.range-slider {
  height: 2px;
  background-color: #bbbbbb;
}

.range-slider input[type="range"] {
  display: none;
}

.range-slider__thumb {
  width: 12px !important;
  height: 12px !important;
}

.range-slider__thumb:first-of-type {
  background: #fe7d62 !important;
}
.range-slider__thumb:nth-of-type(2) {
  background: #e85ea3 !important;
}

.range-slider__range {
  background: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%) !important;
}

.no-visible {
  outline: none !important;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

.ril-toolbar-right .ril-toolbar__item:first-child,
.ril-toolbar-right .ril-toolbar__item:nth-child(2) {
  display: none;
}

.ril-toolbar-right .ril-toolbar__item:last-child {
  margin-top: 10px;
}

.ril-toolbar-right .ril-toolbar__item:last-child button {
  width: 35px;
  height: 35px;
  background-size: cover;
}

.ril-toolbar {
  background: none;
}

.ReactGridGallery_tile {
  border-radius: 5px;
}

.notification-item > span {
  text-wrap: wrap !important;
}

/*
  Audio
*/

/* .rhap_current-time, .rhap_repeat-button, .rhap_rewind-button, .rhap_forward-button, .rhap_volume-button, .rhap_volume-indicator, .rhap_volume-bar-area, .rhap_volume-controls, .rhap_additional-controls {
  display: none;
}

.rhap_controls-section {
  margin-top: 0 !important;
  order: -1 !important;
}

.rhap_main {
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.rhap_container {
  min-width: 353px
} */

.wave > div {
  background-image: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%) !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
}

/* .wave > div > div > div:nth-of-type(2) {
  background-color: transparent;
 } */

[data-testid="seek-trackbar-trackbar"] {
  opacity: 0;
}

[data-testid="timer"] {
  color: #fff !important;
}

@media screen and (max-width: 551px) {
  .wave,
  .wave > div {
    width: 185px !important;
  }
  .wave > div > div > div:nth-of-type(2) canvas {
    width: 90px !important;
  }
}

thead tr:nth-of-type(2) {
  display: none;
}

thead tr:first-of-type th:first-of-type {
  border-bottom-left-radius: 0;
}

thead tr:first-of-type th:last-of-type {
  border-bottom-right-radius: 0;
}

tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 8px;
}

tbody tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 8px;
}

.DraftEditor-root {
  border: 1px solid #edeaff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  min-height: 156px;
  max-height: 156px;
  overflow-y: auto;
  margin-top: 8px;
  z-index: 9;
}

.public-DraftEditorPlaceholder-root {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: -1;
}

.DraftEditor-root mark {
  background-color: #e85ea3;
  color: #fff;
  padding: 2px;
  border-radius: 2px;
}

.selected-action span {
  font-weight: 600;
}

.search-modal {
  min-height: 70%;
  max-height: 70%;
}

@media screen and (min-width: 1025px) {
  .search-modal {
    width: 1000px !important;
  }
}

@media screen and (max-width: 1024px) {
  .search-modal {
    width: 900px !important;
  }
}

@media screen and (max-width: 640px) {
  .search-modal {
    width: 420px !important;
  }
}

@media screen and (max-width: 459px) {
  .search-modal {
    width: 310px !important;
  }
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}

.blog-title {
  width: 100%;
  height: 350px;
}

.ReactGridGallery_tile-viewport,
.ReactGridGallery_tile {
  height: 300px !important;
}

.no-margin-input {
  margin-top: 0 !important;
}

@keyframes bellRing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.bell-ring {
  animation: bellRing 0.6s ease-in-out;
  transform-origin: top center;
}

.countBox span {
  color: transparent;
  background-image: linear-gradient(90deg, #ff7e5f 0%, #e65ca7 100%);
  background-clip: text;
}